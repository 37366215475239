<template>
  <div class="container">
    <div class="banner">
        <div class="w solution-bg">
            <h3>企业软件定制解决方案</h3>
            <p>丰富的系统架构经验，提供高并发、高可靠性、安全性的技术，<br />CMMI3级及ISO系列认证，构建流程化规范化的研发体系</p>
            <button @click="showKefuBox">在线咨询&nbsp;<i class="icon-im"></i></button>
        </div>
    </div>
    <!--//面临的问题与挑战-->
    <div class="solution-question">
        <div class="w">
            <h3 class="t">面临的问题与挑战</h3>
            <ul>
                <li><span><i class="icon iconfont">&#xe829;</i></span><h3>需求蔓延</h3><p>需求超出实际能力</p></li>
                <li><span><i class="icon iconfont">&#xe71b;</i></span><h3>目标明确</h3><p>企业战略规划与IT项目缺失关联</p></li>
                <li><span><i class="icon iconfont">&#xe6a3;</i></span><h3>沟通不及时</h3><p>信息沟通不及时导致决策失误</p></li>
                <li><span><i class="icon iconfont">&#xe6b3;</i></span><h3>缺乏绩效考核</h3><p>缺乏有效的研发考评与激励机制</p></li>
                <li><span><i class="icon iconfont">&#xe69c;</i></span><h3>资源分配不合理</h3><p>不能保证高效的开发项目</p></li>
            </ul>
        </div>
    </div>
    <!--//解决方案内容-->
    <div class="solution-content">
        <div class="w">
            <div class="desc">软件定制开发是根据企业业务需求定制的软件，软件定制开发按照面向客户的不同分类两类：一种是通用解决方案。根据某些已经体现出的，或预期的问题、不足、缺陷、需求等所提出的一个利用软件
解决问题的方案。提出方案后组织资源进行软件分析、架构、设计、开发、测试工作并以产品的方式销售给需求者。另一种是软件定制开发解决方案。所谓定制，是指接到某个客户或者某个行业的软件
需求后，按照某个客户或者行业的特定要求，为其存在的业务、问题等软件需求专门量身定做一套软件，从而解决特定客户的特定问题。<br />为保证工程能够顺利地进行实施，必须要制定科学、合理、切实可行的实施计划。一方面要从组织上进行落实，成立强有力的项目领导小组和经验丰富的项目实施队伍；另一方面要制定严格的时间进度表，
明确各里程碑的时间。同时还要制定工作原则，以指导项目的全面实施。</div>
            <div class="tab">
                <span class="selected">项目启动</span>
                <span>需求分析</span>
                <span>产品设计</span>
                <span>技术开发</span>
                <span>测试验收</span>
            </div>
            <div class="tab-content">
                <div class="tabc tabshow">
                    <h4>一、项目总体推进计划</h4>
                    <p>为了有效地保证系统开发的质量，整个系统建设的全过程划分为准备、设计、开发、实施和运
行阶段，每个阶段完成相应的任务，确保信息系统的建设。</p>
                    <h4>二、系统实施过程的质量保证</h4>
                    <p>在实施过程中将发生的重大质量保证或由此将产生的质量记录和产品，项目
管理与开发阶段划分密切相关，因此主要按照项目实施的具体阶段划分说明。</p>
                </div>
                <div class="tabc">
                    <h4>三、需求分析阶段</h4>
                    <p>首先需要经双方协调，形成《需求调研计划》及《需求调研大纲》，确定准备工作、需
求调研的内容、方法方式以及人员和日程安排等内容，经双方同意后按此计划开始调研。
调研正式开始前项目开发组应检查所有必要的准备工作已经圆满完成</p>
                </div>
                <div class="tabc">
                    <h4>四、总体设计阶段</h4>
                    <p>项目开发组通过对系统的功能、运行和性能要求加以分析，产生一个高层次的系统结构、
软件结构、接口和数据格式的设计，（其中包括数据库设计），组织评审对其中评审不合格的
部分进一步完善和重新策划，作为后续软件开发和测试的基础</p>
                </div>
                <div class="tabc">
                     <h4>五、详细设计阶段</h4>
                    <p>对功能和性能要求进一步加以分析和细化并且把软件的详细设计文档化，由项目组组织评审，
对其中评审不合格的部分进一步完善和重新策划，作为后续软件开发和测试的基础</p>
                </div>
                <div class="tabc">
                    <h4>七、系统实施和试运行阶段</h4>
                    <p>首先需要经双方交流协调，形成《项目实施计划》，确定实施的准备工作、人员和日
程安排、培训计划、阶段目标等内容，按此计划开始现场实施。正式开始实施前项目
开发组应检查所有必要的准备工作是否已经完成</p>
                    <h4>八、项目验收阶段</h4>
                    <p>在试运行期内系统存在一定的细节性问题是工程项目不可避免的问题，特别是随着用户应用的逐
渐深入，此类需求会逐级提出，此类问题不属于系统的致命性错误；因此当试运行期内所发现的
真正的“问题和错误”收敛到一定数目以下时，各业务子系统经过一段时间的并行工作新系统已基
本可靠，就可以切换到正式运行阶段，开始正式运行</p>
                </div>
            </div>
        </div>
    </div>
    <!--//定制开发技术标准-->
    <div class="solution-customization">
        <div class="w">
            <h3 class="t">定制开发技术准则</h3>
            <div class="d">为确保系统的建设成功与可持续发展，在系统的建设与技术方案设计时我们遵循如下的原则：</div>
            <div class="f">
                <img src="../assets/so_develop_s.png" />
                <div class="g">
                    <div><span class="l" style="background-color:#fbe4d7; color:#ea5414">信息系统设计要考虑到业务未来发展的需要，尽可能设计得简明，降低各功能模块耦合度，并充分考虑兼容性。系统能够支持对多种格式数据的存储</span></div>
                    <div><span style="background-color:#b8dbae; color:#009943">保护已有资源，急用先行，在满足应用需求的前提下，尽量降低建设成本</span></div>
                    <div><span style="background-color:#f6c2c3; color:#c30d22">系统各项技术遵循国际标准、国家标准、行业和相关规范</span></div>
                    <div><span class="l" style="background-color:#dac3de; color:#5f1885">统筹规划和统一设计系统结构，尤其是应用系统建设结构、数据模型结构、数据存储结构以及系统扩展规划等内容，均需从全局出发、从长远的角度考虑</span></div>
                    <div><span class="l" style="background-color:#eeeeee">系统构成必须采用成熟、具有国内先进水平，并符合国际发展趋势的技术、软件产品和设备。在设计过程中充分依照国际上的规范、标准，借鉴国内外目前成熟的主流网络和综合信息系统的体系结构，以保证系统具有较长的生命力和扩展能力。保证先进性的同时还要保证技术的稳定、安全性</span></div>
                    <div><span style="background-color:#b8cae8; color:#005482">系统设计和数据架构设计中充分考虑系统的安全和可靠</span></div>
                </div>
                <div class="clearfix"></div>
            </div>
            <p>以上则是企业软件定制开发的解决方案。企业管理软件定制是定制一套软件，它不单单是软件，更是管理理论和管理经验的具体化、逻辑化，是管理行为的落地，因为企业管理软件设计开发的过
程，是研究这个行业中先进的管理模式和流程甚至更多被多数企业证明了行之有效的管理规律，这些管理经验已经内涵在管理软件的思想、流程、报表内容、统计分析项目、管理层级、信息决策
中。所以有效的企业管理软件是企业咨询顾问形式的企业管理软件，目前只有通用咨询提供此服务。</p>
        </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
    mounted: function() {
        $(function(){
            $(".tab span").hover(function(){
                $(".tab span.selected").removeClass("selected");
                $(this).addClass("selected");
                $('.tab-content .tabshow').removeClass("tabshow");
                $('.tab-content .tabc').eq($(this).index()).addClass("tabshow");
            });
        });
    },
    methods: {
        showKefuBox: function()
        {
            this.$store.commit("setKefuStatus",true);
        }
    }
}
</script>

<style>
.banner h3{ font-size: 36px; color: #ffffff; font-weight: normal; padding-top: 60px; margin:0px;}
.banner p{ font-size: 18px; color: #ffffff; line-height: 30px;}
.banner button{ background-color: #ffffff; border: 1px #ffffff solid; border-radius: 3px; padding: 10px 40px; font-size: 18px; cursor: pointer; margin-top: 30px;}
.banner button i{ font-size: 30px; }
.solution-bg{ background: url("../assets/so_develop_f.png") right bottom no-repeat; height: 90%;}

.solution-question h3.t{ font-size: 25px; font-weight: normal; margin-top: 50px;}
.solution-question ul,.solution-question ul li,.solution-question ul li h3{ margin: 0px; padding: 0px; list-style: none; display: block; line-height: 30px;}
.solution-question ul{  border-right: 1px #eeeeee solid; height: 230px; overflow: hidden; margin: 30px 0px;}
.solution-question ul li{ width: 19%; overflow: hidden; float: left; text-align: center; height: 230px; padding-top: 40px; border-left: 1px #eeeeee solid; cursor: pointer; color: #000000;}
.solution-question ul li span i{ font-size: 45px;}
.solution-question ul li:hover{ color: #004172;}
.solution-question ul li h3{ font-size: 18px;}
.solution-question ul li p{ font-size: 14px;}

.solution-content{ background-color: #edecec; padding: 60px 0px; border-top: 1px #dddddd solid; line-height: 2.0;}
.solution-content .desc{ font-size: 16px; line-height:2.0;}
.solution-content .tab{ text-align: center; margin: 30px 0px;}
.solution-content .tab span{ font-size: 18px; padding: 10px 25px; cursor: pointer;}
.solution-content .tab span:hover,.solution-content .tab span.selected{ background-color: #ffffff; border-radius: 5px;}
.solution-content .tab-content{ background-color: #ffffff; border-radius: 5px; padding: 50px;}
.solution-content .tab-content .tabc{ width: 90%; display: none;}
.solution-content .tab-content .tabshow{ display: block;}
.solution-content .tab-content .tabc h4{ font-size: 18px; font-weight: normal;}
.solution-content .tab-content .tabc p{ font-size: 14px; line-height: 40px;}

.solution-customization{ margin-bottom: 50px;}
.solution-customization h3.t{ font-size: 25px; font-weight: normal; margin-top: 50px;}
.solution-customization p{ font-size: 16px; line-height: 2.0;}
.solution-customization .d{ font-size: 18px; line-height: 24px;}
.solution-customization .f{ margin: 30px 0px;}
.solution-customization .f img{ float: left;}
.solution-customization .g{ float:right; width: 60%;}
.solution-customization .g div{ margin-bottom: 10px; clear: both;}
.solution-customization .g span{ font-size: 14px; line-height: 30px; background-color: #eeeeee; border-radius: 5px; padding: 10px;}
.solution-customization .g span.l{ display: block;}
</style>
